import React from "react"
import { Link } from "gatsby"

export default function Header() {
  return (
    <h1>
      <Link to="/">Golf Course Info</Link>
    </h1>
  )
}
