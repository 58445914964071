import React from "react"
import { Form, Row, Col } from "react-bootstrap"
import { navigate, graphql, useStaticQuery } from "gatsby"
import { Typeahead } from "react-bootstrap-typeahead"

export default function SearchForm() {
  const data = useStaticQuery(graphql`
    query courseSearchQuery {
      allCourseJson {
        edges {
          node {
            course_name
            slug
          }
        }
      }
    }
  `)

  //console.log(data)
  //const [name, setName] = useState("")
  //const course_list = data.allCourseJson.edges
  const handleChange = e => {
    if(e[0] !== undefined){
      navigate(`/course/${e[0].node.slug}`)
    }
  }
  return (
    <Form>
      <Form.Group controlId="searchForm">
        <Row>
          <Col lg={4}>
            <Typeahead
              minLength = { 1 }
              id="courseSearch"
              labelKey={option => `${option.node.course_name}`}
              placeholder="Search for Course..."
              onChange={handleChange}
              options={data.allCourseJson.edges}
            />
          </Col>
        </Row>
      </Form.Group>
    </Form>
  )
}
